
body
{
  font-family: "Poppins", sans-serif;
}
.topHead{
  background: #2C98F0;
  padding: 12px 0;
  position: fixed;
  top: 0;
  z-index: 9;
  left: 0;
  right: 0;
}
.mobilepBnr
{
  display: none;
}
section.moble-country {
  display: none;
}
.cusotmeHeader img {
  max-width: 160px;
}
.cusotmeHeader {
  padding: 16px 140px 0;
  position: absolute;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  right: 0;
  left: 0;
}
.discount {
  position: relative;
  margin-top: 30px;
  text-align: center;
}
.bnr-img.mobile {
  display: none;
}
.wtsApp {
  text-align: right;
}
.wtsApp p {
  margin: 0;
  color: #fff;
  font-size: 16px;
  /* font-family: 'Bw-Gradual-medium'; */
  font-weight: 700;
  font-style: italic;
}
.language {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.language label {
  font-size: 13px;
  color: #fff;
  margin: 0;
  padding-right: 14px;
}
.language .formGroup {
  margin-left: 18px;
}
.language .formGroup select {
  background: transparent;
  border: 0;
  outline: none;
  font-size: 13px;
  padding-right: 3px;
  color: #fff;
}
.menuHead {
  /* background: #000000; */
  background: #2C98F0;
  padding: 7px 0;
  /* padding-top: 33px; */
}
.menuHead ul.navbar{
  display: flex;
  margin-left: auto;
  margin-bottom: 0;
  padding: 0;
}
section.counter-section .row {
  padding: 0 200px;
}
.viewAll {
  display: flex;
  align-items: center;
}
.menuHead ul.navbar li {
  margin-left: 50px;
  list-style: none;
}
.menuHead ul.navbar li.active a {
  border-bottom: 2px solid;
}
.menuHead ul.navbar li a {
  font-size: 15px;
  color: #fff;
  padding: 0 !important;
  text-decoration: none;
  font-weight: 500;
}
.loginBtn a {
  background: #2C98F0;
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  padding: 13px 37px;
  border-radius: 7px;
  margin-left: 41px;
  position: relative;
  font-weight: 500;
}
.loginBtn a::before {
  position: absolute;
  width: 6px;
  height: 6px;
  content: "";
  border-radius: 10px;
  background: #fff;
  left: 25px;
  top: 20px;
}
.bnr-img img {
  width: 100%;
}
section.banner-section {
  position: relative;
}
.banner-overlay {
  position: absolute;
  top: 0;
  /* transform: translateY(-50%); */
  margin: 0 auto;
  left: 0;
  right: 0;
  padding-top: 25px;
}
.footerLogo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footerLogo img {
  max-width: 160px;
}
.left-abner h2 {
  font-size: 45px;
  font-weight: 600;
  line-height: 59px;
  color: #000;
  text-align: center;
  margin-bottom: 30px;
}
.niyoLogo {
  text-align: center;
  margin-bottom: 20px;
}
.verify-lable {
  display: flex;
  align-items: self-end;
  justify-content: space-between;
  padding: 11px 0 0px;
}
a.verifylink {
  text-align: right;
  display: table;
  text-decoration: none;
  margin-left: auto;
  background: #2C98F0;
  color: #fff;
  padding: 4px 13px;
  border-radius: 4px;
  font-size: 15px;
  cursor: pointer;
  position: relative;
  top: -7px;
}
.Serch {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}
.Serch input {
  height: 50px;
  width: 100%;
  border: 0;
  border-radius: 8px;
  font-size: 15px;
  outline: none;
  padding: 0 14px;
}
.ourCountry {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  background: #fdfdfd69;
  border-radius: 8px;
  padding: 13px 13px 15px;
  margin-top: 12px;
}
.esminHeader {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: space-between;
}

.ourCountry .countrySlide:last-child {
  margin: 0;
}
.ourCountry .countrySlide {
  list-style: none;
  margin-right: 32px;
  position: relative;
}
.ourCountry .countrySlide::before {
  position: absolute;
  right: -15px;
  top: 5px;
  width: 1px;
  height: 18px;
  background: #000000;
  content: "";
}
.countrySlide button.slick-arrow {
  display: none !important;
}
.ourCountry .countrySlide:last-child::before {
  display: none;
}
.ourCountry h6 {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.ourCountry .countrySlide span, .ourCountry .countrySlide span a {
  margin: 0;
  font-size: 13px;
  font-weight: 600;
  color: #000;
  text-decoration: none;
  line-height: 1;
  display: inline-block;
  min-height: 26px;
  display: flex;
  align-items: flex-end;
}
.countrySlide span {
  margin-right: 0px !important;
}
.Serch button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  border: 0;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #000;
  padding: 0 20px;
  border-radius: 0 8px 8px 0;
}
.left-abner p:last-child {
  border: 0;
}
.left-abner p {
  color: #303030;
  font-size: 15px;
  font-weight: 400;
  line-height: 27px;
  max-width: 100%;
  margin: 0;
  padding: 10px 0;
  border-color: #d8d8d8;
}
.right-abner {
  background: #FFFFFF;
  padding: 50px;
  border-radius: 10px;
  max-width: 81%;
  margin-left: auto;
}

.right-abner h4 {
  font-size: 22px;
  line-height: 31px;
  font-weight: 500;
  color: #1D2C38;
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px;
}
.form-group label {
  font-size: 18px;
  color: #001D23;
  font-weight: 600;
  width: 100%;
  display: table;
  margin-bottom: 5px;
}
.form-group input {
  height: 52px;
  border: 1px solid #DDDDDD;
  padding: 0 20px;
  width: 100%;
  border-radius: 5px;
}
.right-abner form {
  padding-top: 19px;
}
input[type="submit"] {
  background: #000000;
  color: #fff;
  margin-top: 10px;
  border-radius: 5px;
  border: 0;
  font-weight: 600;
}
#exampleModal input[type="tel"] {
  height: 57px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
section.counter-section {
  background: #15151B;
  padding: 80px 0;
}
.count-inner {
  text-align: center;
}
.count-inner h5 {
  font-size: 48px;
  color: #fff;
  font-weight: 600;
  margin: 0;
}
.count-inner span {
  font-size: 20px;
  color: #fff;
}
section.esims {
  background: #ffffff;
  padding: 80px 0 80px;
}
section.esims h3 {
  color: #000;
  font-size: 35px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0px;
}
.esminHeader button {
  border: 0;
  background: transparent;
  padding: 0;
  color: #2c98f0;
  font-weight: 500;
  font-size: 15px;
}
section.esims .form-group {
  max-width: 625px;
  margin: 0 auto;
  position: relative;
}
section.esims .form-group input {
  height: 48px;
  background: transparent;
  color: #fff;
  font-weight: 300;
  border: 1px solid #FFFFFF66;
}
section.mob-section {
  display: none;
}
section.esims .form-group button {
  position: absolute;
  right: 13px;
  top: 11px;
  background: transparent;
  border: 0;
  color: #FFFFFF66;
}
ul.filter-am {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 34px 0 70px;
  padding: 0;
}

ul.filter-am li {
  list-style: none;
  margin: 0 5px;
}
ul.filter-am li span {
  border: 1px solid #ffffff75;
  font-size: 15px;
  font-weight: 400;
  padding: 10px 24px;
  border-radius: 100px;
  color: #ffffff75;
}

.FirtPack {
  background: #E3EFF9;
  border-radius: 10px;
  padding: 23px 13px;
  margin-bottom: 19px;
}
.PackHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PackHeadLeft, .PackHeadRight {
  margin-bottom: 8px;
}
.PackHeadLeft h4 {
  font-size: 15px;
  color: #ffff;
  margin: 0;
}
.PackHeadLeft p {
  font-size: 12px;
  color: #fff;
  margin: 0;
}
ul.filter-am li.active span {
  background: #fff;
  color: #000;
}
.PackHeadRight span {
  font-size: 13px;
  background: #2C98F0;
  border-radius: 100px;
  font-weight: 600;
  padding: 3px 14px;
  color: #fff;
}
.esimBlogs ul {
  padding: 0;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  padding: 0;
}
.FirtPack ul li {
  margin: 0px 7px 0 0;
  background: #fff;
  border-radius: 10px;
  width: 33.33%;
  padding: 13px 9px;
  list-style: none;
}
.FirtPack ul li:last-child {
  margin: 0;
}
.ourCountry .countrySlide .slick-slide {
  padding: 0 4px;
}
.cntFlags a {
  text-decoration: none;
}
.ourCountry .countrySlide .slick-slide a {
  text-decoration: none;
}
span.bgCountry img {
  max-width: 88%;
  margin: 0;
  margin-left: auto;
  border: 0;
}
span.bgCountry {
  position: absolute;
  bottom: 0;
  padding: 0;
  margin: 0;
  right: 0;
  opacity: 0.3;
}
.cntFlags {
  background: #fff;
  border-radius: 10px;
  padding: 12px;
  position: relative;
}
span.bgCountry {
  pointer-events: none;
}
.cntFlags img {
  margin-bottom: 17px;
  max-width: 26px;
  border: 1px solid #989898;
  border-radius: 100px;
}
.FirtPack ul li span {
  font-size: 12px;
  background: transparent;
  padding: 0;
  color: #000;
  font-weight: 600;
}
.FirtPack ul li h5 {
  font-size: 13px;
  color: #000;
  margin: 20px 0 0;
  font-weight: 600;
}
.buySimBtn {
  background: #000;
  font-size: 16px;
  text-decoration: none;
  width: 65%;
  display: table;
  text-align: center;
  border-radius: 4px;
  padding: 10px 0;
  color: #fff;
  margin-top: 29px;
  cursor: pointer;
  font-weight: 600;
}
.viewAll a {
  width: 35%;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: #2c98f0;
  text-align: center;
  padding: 10px 0;
  margin-top: 29px;
}
section.esims .FirtPack h3 span {
  font-size: 16px;
  font-weight: 500;
}
section.esims .FirtPack h3 {
  font-size: 21px;
  text-align: left;
  margin: 16px 0 22px;
  font-weight: 600;
  line-height: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 179px;
  white-space: nowrap;
}
.FirtPack ul {
  padding: 0;
  margin: 0;
  display: flex;
}
footer.footer-main {
  background: #2C98F0;
  padding: 70px 0 80px;
}
.fottre-left p {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: #fff;
  max-width: 470px;
  margin: 20px auto 0;
}
.fottre-link h6 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 23px;
}
.fottre-link li {
  list-style: none;
  margin-bottom: 12px;
}
.fottre-link ul {
  padding: 0;
  margin: 0;
}
.fottre-link li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
}
.fottre-form {
  background: #000000;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}
.fottre-form h5 {
  color: #2C98F0;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 10px;
}
.fottre-form p {
  color: #fff;
  font-size: 16px;
  margin: 12px 0 16px;
}
.fottre-form .form-group input {
  height: 60px;
  border: 1px solid #CFDBF1;
  background: transparent;
}
.fottre-form .form-group {
  position: relative;
  padding: 0 12px;
}
.fottre-form .form-group button {
  background: #2C98F0;
  border: 0;
  color: #fff;
  position: absolute;
  right: 22px;
  top: 12px;
  padding: 8px 10px 4px;
  border-radius: 4px;
}
section {
  overflow: hidden;
}
.EsimModal .formGroup div {
  color: red;
  font-size: 13px;
}
.left-abner {
  max-width: 785px;
  margin: 0 auto;
}
.search-suggestion::-webkit-scrollbar {
  width: 7px;
}

.search-suggestion::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0); 
  border-radius: 10px;
}
 
.search-suggestion::-webkit-scrollbar-thumb {
  background: #2C98F0; 
  border-radius: 10px;
}

.search-suggestion::-webkit-scrollbar-thumb:hover {
  background: #2C98F0; 
}
.NotFound {
  text-align: center;
}
.NotFound img {
  max-width: 270px;
}
.PackHeadRight {
  min-width: 86px;
}
.left-abner p:hover {
  color: #2c98f0;
}
.niyoLogo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.niyoLogo img {
  max-width: 160px;
}
.conText {
  text-align: center;
}
.conText h4 {
  font-size: 24px;
  font-weight: 600;
  margin: -20px 0 33px;
}
.conText h5 {
  font-size: 20px;
  color: #2C9DF1;
  font-weight: 600;
  margin-bottom: 12px;
}
.conText p {
  font-size: 14px;
  color: #000000;
  line-height: 19px;
}
.conText a {
  background: #2C9DF1;
  border-radius: 8px;
  padding: 12px 24px 12px 24px;
  color: #fff;
  text-decoration: none;
  margin: 20px auto 16px;
  display: table;
}
.modal-content {
  border: 0;
  border-radius: 20px;
}
.Congratmodal .modal-header button {
  background: transparent;
  color: #545454;
  padding: 0;
  top: 26px;
  right: 20px;
  opacity: 1;
  font-weight: 100;
}
.Congratmodal .modal-header button i {
  -webkit-text-stroke: 1px #ffffff;
}
.Congratmodal .modal {
  background: #00000085;
}
.conText img {
  width: 100%;
}
@media (max-width:1400px)
{
  .left-abner h2 {
    font-size: 35px;
    line-height: 1.3;
    margin-bottom: 16px;
}
.FirtPack {
  padding: 23px 11px;
}
.discount {
  margin-top: 50px;
  padding-left: 0;
}
  .banner-overlay {
    padding-top: 18px;
}
    section.counter-section .row {
      padding: 0 130px;
  }
  .niyoLogo {
    margin-bottom: 10px;
}
    section.esims .FirtPack h3 {
      font-size: 18px;
      width: 139px;
      padding-bottom: 6px;
      margin-top: 0;
  }
    ul.filter-am li span {
        padding: 10px 16px;
    }
    section.esims .FirtPack h3 span {
      font-size: 14px;
      top: 4px;
  }
  .PackHead {
    align-items: flex-start;
}
    ul.filter-am li span {
        font-size: 15px;
        padding: 10px 16px;
    }
    .FirtPack ul li {
        margin: 0px 4px 0 0;
        padding: 11px 7px;
    }
    .fottre-form .form-group {  
        padding: 0;
    }
    .fottre-form .form-group input {
        font-size: 13px;
    }
    .FirtPack ul li h5 {
      font-size: 12px;
  }
    .container {
      max-width: 1240px  !important;
  }
}
@media (max-width:991px)
{
  .left-abner h2 {
    font-size: 29px;
    line-height: 1.3;
    margin: 0 0 14px;
}
.cusotmeHeader {
  padding: 16px 30px 0px;
}
.cusotmeHeader img {
  max-width: 140px;
}
.discount {
  margin-top: 20px;
  padding-left: 0;
  text-align: center;
}
section.counter-section .row {
  padding: 0 0px;
}
.bnr-img img {
  height: 480px;
  object-fit: cover;
  object-position: top;
}
section.esims h3 {
  font-size: 27px;
}
section.esims .col-md-3 {
  width: 50%;
}
footer.footer-main {
  padding: 50px 0 50px;
}
section.esims {
  padding: 60px 0 60px;
}
}
@media (max-width:767px)
{
    .topHead .col-5 {
        width: 100%;
    }
    section.moble-country {
      display: block;
      padding: 0 0 14px;
  }
  section.moble-country .countrySlide {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}
section.moble-country .countrySlide .cntFlags {
  width: 30.3%;
  background: #EDF8FF;
  margin: 4px 4px;
}
    .banner-overlay .ourCountry {
      display: none;
  }
  .desktopBnr
  {
    display: none;
  }
.mobilepBnr
{
  display: block;
}

    .conText img {
      max-width: 85%;
  }
  .cusotmeHeader {
    padding: 10px 15px 0px;
}
  .conText h4 {
    font-size: 21px;
    margin: -13px 0 30px;
}
.conText p {
  font-size: 13px;
}
.cusotmeHeader img {
  max-width: 110px;
}
.conText p br
{
  display: none ;
}
.conText p b {
  width: 100%;
  display: table;
}
.Congratmodal .modal-dialog {
  transform: translateY(0) !important;
  top: 120px;
}
    .niyoLogo img {
      max-width: 104px;
  }
    .bnr-img.desktop {
      display: none;
  }
  .banner-overlay {
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    display: flex;
    align-items: center;
}
  .bnr-img.mobile {
    display: block;
}
    .esminHeader {
      margin-bottom: 20px;
      flex-direction: column;
  }
    .count-inner {
      padding: 10px 0;
  }
  .footerLogo {
    flex-wrap: wrap;
}
.footerLogo p {
  order: 1;
  width: 100%;
  max-width: 100%;
  margin: 0 0 25px;
  border-bottom: 1px solid #FFFFFF;
  padding-bottom: 21px;
}
.footerLogo a {
  order: 2;
}
  .esminHeader button {
    margin-left: auto;
    margin-top: 23px;
}
    .modal-dialog {
      max-width: 90%;
  }
  .leftcheckoutable table tr td {
    padding: 14px 12px;
}
  .modal-body {
    padding: 6px;
}
    .topHead .col-7 {
        display: none;
    }
    section.mob-section {
        display: block;
    }
    .language label {
        font-size: 12px;
    }
    .language .formGroup select {
        font-size: 12px;
        padding-right: 1px;
    }
    ul.filter-am {          
        flex-wrap: wrap;
    }
    .left-abner img {
        max-width: 210px;
    }
    .bnr-img img {
      object-fit: cover;
      height: 350px;
  }
  .discount img {
    max-width: 170px;
}
    .left-abner h2 {
      font-size: 20px;
      line-height: 1.3;
    }
    .niyoLogo {
      padding: 25px 15px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0;
  }
  .banner-overlay {
    padding-top: 108px;
}
  .footerLogo img {
    max-width: 104px;
}
  section.counter-section .col-md-4 {
    width: 33.33%;
}

  .Serch input {
    font-size: 14px;
}
    .left-abner p {
        font-size: 14px;
        line-height: 23px;
    }
    .Serch button {
      width: 100%;
      position: static;
      border-radius: 8px;
      margin-top: 9px;
  }
  .ourCountry ul {
    flex-wrap: wrap;
}
.ourCountry ul li {
  margin-right: 25px;
}
.ourCountry ul li::before {
  right: -13px;
}
    .right-abner {
        padding: 22px;
    }
    section.banner-section .right-abner {
        display: none;
    }
    .loginBtn a {
        font-size: 13px;
        padding: 10px 15px;
        margin-left: 0px;
        position: relative;
        left: -50px;
    }
    .loginBtn a::before {
        display: none;
    }
    button.navbar-toggler {
        position: absolute;
        right: 0;
        top: 10px;
        background: #fff;
        padding: 9px 5px 0;
        color: #2c98f0;
        line-height: 0;
    }
    div#navbarSupportedContent {
        position: absolute;
        background: #fff;
        top: 63px;
        left: 0;
        right: 0;
        padding: 20px;
        border-radius: 10px;
        z-index: 9;
    }
    .menuHead ul.navbar li a {
        color: #262626;
    }
    .menuHead ul.navbar {
        flex-direction: column;
        justify-content: flex-start;
    }
    .menuHead ul.navbar li {
        width: 100%;
        margin: 7px 0;
    }
    section.mob-section {
        background: #000;
        padding: 33px 0;
    }
    .right-abner {
        padding: 22px;
        margin: 0;
        max-width: 100%;
    }
    .right-abner h4 {
        font-size: 20px;
        line-height: 28px;
        padding-bottom: 19px;
    }
    .right-abner form {
        padding-top: 12px;
    }
    .form-group label {
        font-size: 16px;
        margin-bottom: 4px;
    }
    section.counter-section {
      padding: 18px 0 14px;
  }
    section.counter-section .col-md-3 {
        width: 50%;
        margin: 16px 0;
    }
    .count-inner span {
      font-size: 12px;
      max-width: 80px;
      display: flex;
      line-height: 1.3;
      margin: 7px auto 0;
  }
  section.counter-section {
    background: #1F1F2C;
}
    .count-inner h5 {
        font-size: 21px;
    }
    ul.filter-am {
        margin: 24px 0 31px;
    }
    footer.footer-main {
        padding: 30px 0 30px;
    }
    section.esims {
      padding: 40px 0 20px;
  }
    section.esims h3 {
        font-size: 22px;
    }
    section.esims .col-md-3 {
      width: 100%;
  }
    ul.filter-am li {
        margin: 17px 5px;
    }
    
    .fottre-link h6 {
        font-size: 19px;
        margin-bottom: 13px;
        margin-top: 33px;
    }
}