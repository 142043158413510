.EsimModal {
    padding: 10px;
}
.EsimModal .formGroup input {
    width: 100%;
    height: 46px;
    border: 1px solid #ddd;
    padding: 0 16px;
    border-radius: 10px;
}
.error
{
    color: red;
    font-size: 13px;
}
.EsimModal .formGroup {
    margin: 14px 0 0;
}
.EsimModal .formGroup  label
{
    width: 100%;
    display: table;
    padding-bottom: 5px;
}